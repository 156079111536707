import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { defer } from 'lodash';
import classNames from 'classnames';

import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import searchPath from '@starbucks-web/pattern-library/lib/icons/search';

import SharedForm from 'shared/app/components/shared-form';

import styles from './styles.cssm';
import messages from './messages';

const LocatorSearch = forwardRef(({
  className,
  searchDefaultValue,
  mapInstance,
  handleSearch,
}, ref) => {
  const { formatMessage } = useIntl();

  const locatorSearchInputRef = useRef(null);

  const [searchValue, setSearchValue] = useState(searchDefaultValue);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchValue === '') {
      return;
    }

    handleSearch(searchValue);

    // Hide the keyboard on android devices
    defer(() => locatorSearchInputRef.current.blur());
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (mapInstance && locatorSearchInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        locatorSearchInputRef.current
      );

      autocomplete.setTypes(['geocode']);
      autocomplete.bindTo('bounds', mapInstance);

      const googleListener = window.google.maps.event.addListener(
        autocomplete,
        'place_changed',
        () => {
          const place = autocomplete.getPlace();
          const formattedAddress = place?.formatted_address;

          // condition handles user inputting their own search query
          // as opposed to selecting from the Google suggested options
          if (formattedAddress) {
            setSearchValue(formattedAddress);
            handleSearch(formattedAddress);
          }
        }
      );

      return () => {
        window.google.maps.event.removeListener(googleListener);
      };
    }
  }, [mapInstance]);

  return (
    <SharedForm
      action="/store-locator"
      aria-label={formatMessage(messages.formLabel)}
      className={classNames(styles.form, className)}
      method="get"
      onSubmit={handleSubmit}
      ref={ref}
      role="search"
    >
      <input
        aria-describedby="autoCompleteList"
        aria-label={formatMessage(messages.inputLabel)}
        className={`text-sm pr7 ${styles.input}`}
        data-e2e="searchTermInput"
        name="place"
        onChange={handleChange}
        placeholder={formatMessage(messages.placeholder)}
        ref={locatorSearchInputRef}
        type="search"
        value={searchValue}
      />
      <span className="hiddenVisually" id="autoCompleteList">
       {formatMessage(messages.autoCompleteListLabel)}
      </span>
      <div className={classNames(styles.buttonContainer)}>
        <button
          aria-label={formatMessage(messages.buttonLabel)}
          className={styles.button}
          data-e2e="submitSearchTermButton"
        >
          <Icon className={styles.icon} path={searchPath} size="24px" />
        </button>
      </div>
    </SharedForm>
  );
});

LocatorSearch.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  mapInstance: PropTypes.object,
};

export default LocatorSearch;
