import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@starbucks-web/pattern-library/lib/components/button';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import { doLogin } from 'shared/app/shell';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import isApplePaySupported from 'shared/app/utils/is-apple-pay-supported';
import { CONTINUE_AS_GUEST } from 'shared/app/state/action-creators/types';
import { hasGuestSessionSelector } from 'shared/app/bundles/user';
import {
  trackCheckoutAsGuestClick,
  trackGuestSrCtaClick,
  UO_GUEST_SIGN_IN,
  UO_GUEST_SIGN_UP,
} from 'shared/app/state/track-event';
import SharedBottomSheet from 'shared/app/components/bottom-sheet-with-messages';
import { withOptimizely } from 'shared/app/components/optimizely';
import useGcoV2Flag from 'shared/app/hooks/use-gco-v2-flag';

export const JoinSignInBottomSheetTitleId = 'JoinSignInBottomSheetTitleId';

const JoinRewardsSigninSheet = ({
  targetUrl,
  headingContent,
  preJoinPromise,
  preSignInPromise,
  onChooseGuestCheckout = () => {},
  storeSupportsGuestCheckout,
  optimizely,
}) => {
  const dispatch = useDispatch();
  const hasGuestSession = useSelector(hasGuestSessionSelector);
  const { isGcoV2Enabled } = useGcoV2Flag(optimizely);

  const hash = window.location.hash;
  const returnUrl = targetUrl || window.location.href.replace(hash, '');
  const encodedReturnUrl = encodeURIComponent(returnUrl);
  const { closeModal } = useModalContext();

  const onJoinClickHandler = () => {
    trackGuestSrCtaClick({
      'click_target': UO_GUEST_SIGN_UP,
      'cta_placement': 'MOP_CART_VIEW',
    });

    // Adding a delay to allow the track event to succeed
    setTimeout(() => {
      const preAction = preJoinPromise
        ? preJoinPromise
        : () => Promise.resolve();

      preAction().then(() => {
        window.location.href = `/account/create?returnUrl=${encodedReturnUrl}`;
      });
    }, 200);
  };

  const onSigninClickHandler = () => {
    trackGuestSrCtaClick({
      'click_target': UO_GUEST_SIGN_IN,
      'cta_placement': 'MOP_CART_VIEW',
    });

    // Adding a delay to allow the track event to succeed
    setTimeout(() => {
      const preAction = preSignInPromise
        ? preSignInPromise
        : () => Promise.resolve();

      preAction().then(() => {
        dispatch(doLogin({ returnUrl }));
      });
    }, 200);
  };

  const onCheckoutAsGuest = () => {
    trackCheckoutAsGuestClick();
    dispatch({ type: CONTINUE_AS_GUEST });
    closeModal();
    onChooseGuestCheckout();
  };

  return (
    <SharedBottomSheet
      headingChildren={headingContent}
      headingProps={{
        id: JoinSignInBottomSheetTitleId,
      }}
    >
      <div className="mt6">
        {hasGuestSession &&
          storeSupportsGuestCheckout &&
          (isApplePaySupported || isGcoV2Enabled) && (
            <p className="mb4">
              <Button
                className="mxn3 text-lg"
                data-e2e="checkoutAsGuestButton"
                onClick={onCheckoutAsGuest}
                visualStyle="textOnly"
              >
                <FormattedMessage {...sharedCallsToAction.checkoutAsGuest} />
              </Button>
            </p>
          )}

        <p className="mb4">
          <Button
            className="mxn3 text-lg"
            data-e2e="joinNowButton"
            onClick={onJoinClickHandler}
            visualStyle="textOnly"
          >
            <FormattedMessage {...sharedCallsToAction.joinNow} />
          </Button>
        </p>
        <p className="mb4">
          <Button
            className="mxn3 text-lg"
            data-e2e="signInButton"
            onClick={onSigninClickHandler}
            visualStyle="textOnly"
          >
            <FormattedMessage {...sharedCallsToAction.signin} />
          </Button>
        </p>
      </div>
    </SharedBottomSheet>
  );
};

export default withOptimizely(JoinRewardsSigninSheet);
