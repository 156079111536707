/* eslint-disable max-statements */

import React, { useEffect, useState } from 'react';
import { push as pushAction } from 'redux-first-history';
import { useSelector, connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { v4 as uuidv4 } from 'uuid';

import LoadingCoffeeCup from '@starbucks-web/pattern-library/lib/components/loading-coffee-cup';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import Frap from 'shared/app/components/frap-with-messages';

import SharedBottomSheet from 'shared/app/components/bottom-sheet-with-messages';
import FrapContainer from 'shared/app/components/frap-container';
import PaymentInstrumentSelect from 'shared/app/components/payment-instrument-select';

import {
  addNotification as addNotificationAction,
  setBottomSheetUrl as setBottomSheetUrlAction,
} from 'shared/app/shell';
import { bottomSheetUrlSelector } from 'shared/app/shell/state/selectors/app-ui';
import { setSessionBottomSheetReturnUrl } from 'shared/app/utils/session-storage-bottom-sheet';

import { payPalScriptUrlSelector } from 'shared/app/state/selectors/pay-pal';
import {
  loadScript as loadScriptAction,
  payPalScriptLoadedSelector,
} from 'shared/app/bundles/scripts';
import {
  profileStatusSelector,
  profileDataSelector,
  signedInSelector,
} from 'shared/app/bundles/user';

import {
  ADD_PAYMENT,
  addPayPalPaymentInstrument as addPayPalPaymentInstrumentAction,
  getPayPalBillingAgreementToken as getPayPalBillingAgreementTokenAction,
  paymentInstrumentsLoadingSelector,
  PAY_PAL_LOADING_OFF,
  PAY_PAL_LOADING_ON,
  paymentServicesPendingSelector,
  setPayPalLoadingState as setPayPalLoadingStateAction,
} from 'shared/app/bundles/wallet';

import {
  allGiftPaymentInstrumentsSelector,
  giftPaymentInstrumentsSelector,
  guestPaymentInstrumentSelector,
  hasGuestPaymentInstrumentSelector,
  selectedGiftPaymentInstrumentIdSelector,
} from '../../state/selectors/payments';

import {
  purchaseGift as purchaseGiftAction,
  setSelectedPaymentInstrumentId as setSelectedPaymentInstrumentIdAction,
  updateGiftFormData as updateGiftFormDataAction,
} from '../../state/action-creators';
import {
  giftFormDataSelector,
  currentCardSelector,
  expectingGiftResponseSelector,
  giftAmountTotalSelector,
  hasRequiredGiftFormDataSelector,
  isGroupGiftSelector,
  numberOfRecipientsSelector,
} from '../../state/selectors';
import { getSessionGiftFormData } from '../../utils/session-storage-gift';

import { sharedCallsToAction, commonMessages } from 'shared/app/messages';
import { paymentMessages } from 'shared/app/messages/payments';
import messages from './messages';
import styles from './styles.cssm';

const PAYPAL = 'paypal';
const PAYPAL_TYPE_NAME = 'PayPal';
const GUEST_PAYMENT = 'guest';

// eslint-disable-next-line complexity
export const GiftPaymentBottomSheet = (props) => {
  const {
    // selectors
    allGiftPaymentInstruments,
    bottomSheetUrl,
    currentCard,
    expectingGiftResponse,
    giftAmount,
    giftFormData,
    guestPaymentInstrument,
    hasGuestPaymentInstrument,
    hasRequiredGiftFormData,
    isGroupGift,
    paymentInstruments,
    paymentInstrumentsLoading,
    paymentServicesPending,
    payPalScriptLoaded,
    payPalScriptUrl,
    profileData,
    profileStatus,
    selectedPaymentInstrumentId,
    signedIn,
    // actions
    addNotification,
    addPayPalPaymentInstrument,
    getPayPalBillingAgreementToken,
    loadScript,
    purchaseGift,
    push,
    setBottomSheetUrl,
    setPayPalLoadingState,
    setSelectedPaymentInstrumentId,
    updateGiftFormData,
  } = props;

  const { formatMessage, formatNumber, formats } = useIntl();
  const { openModal, closeModal } = useModalContext();
  const selectedGiftCardNumber = currentCard?.data?.productNumber;
  const altText = currentCard?.data?.altText;
  const largeImageUrl = currentCard?.data?.largeImageUrl;

  const numberOfRecipients = useSelector(numberOfRecipientsSelector);

  const [hideGiftPaymentBottomSheet, setHideGiftPaymentBottomSheet] =
    useState(false);
  // const [payPalTransactionId, setPayPalTransactionId] = useState(null);
  const [showPayPalButton, setShowPayPalButton] = useState(false);
  const [updatedGiftFormData, setUpdatedGiftFormData] = useState(false);
  // local state for storing the requestId we use to send to gift purchase API
  const [requestId] = useState(uuidv4());

  useEffect(() => {
    setShowPayPalButton(selectedPaymentInstrumentId === PAYPAL);
  }, [selectedPaymentInstrumentId]);

  const getSelectedPaymentInstrumentType = () => {
    const payment = paymentInstruments?.find(
      (item) => item?.paymentInstrumentId === selectedPaymentInstrumentId
    );
    const paymentType = payment?.paymentType ?? '';
    return paymentType?.toLowerCase() === PAYPAL ? PAYPAL_TYPE_NAME : 'CC';
  };

  useEffect(() => {
    if (
      isGroupGift &&
      (getSelectedPaymentInstrumentType() === PAYPAL_TYPE_NAME ||
        selectedPaymentInstrumentId === PAYPAL)
    ) {
      setSelectedPaymentInstrumentId(null); // default will be selected immediately after
    }
  }, [isGroupGift, selectedPaymentInstrumentId]);

  useEffect(() => {
    if (signedIn && profileStatus === 'success' && !profileData) {
      setHideGiftPaymentBottomSheet(true);
    }

    // the gift form data may be null until it's loaded
    // this handles a scenario when a user:
    // 1- starts in guest mode
    // 2- signs-in from the bottom sheet
    // 3- returns back to the gift card page
    // => we keep the bottom sheet open so the user can complete the purchase

    if (updatedGiftFormData && !hasRequiredGiftFormData) {
      setHideGiftPaymentBottomSheet(true);
    }

    if (profileStatus === 'success' && selectedGiftCardNumber) {
      if (!giftFormData || !hasRequiredGiftFormData) {
        const savedSessionGiftFormData = getSessionGiftFormData();

        const currentGiftFormData = {
          ...savedSessionGiftFormData,
          productNumber: selectedGiftCardNumber,
        };

        updateGiftFormData(currentGiftFormData);
        setUpdatedGiftFormData(true);
      }
    }
  }, [profileStatus, selectedGiftCardNumber, updatedGiftFormData]);

  const renderPaypalButton = () => {
    let payPalTransactionId = null;

    paypal // eslint-disable-next-line new-cap
      .Buttons({
        createBillingAgreement: () => {
          return getPayPalBillingAgreementToken().then((agreement) => {
            // put reload frap in loading state prior to removing PayPal button
            setPayPalLoadingState(PAY_PAL_LOADING_ON);
            payPalTransactionId = agreement.transactionId;
            return agreement.billingToken;
          });
        },
        onApprove: (data) => {
          setShowPayPalButton(false);
          addPayPalPaymentInstrument(data.billingToken, payPalTransactionId)
            .then((newPaymentInstrument) => {
              setPayPalLoadingState(PAY_PAL_LOADING_OFF);
              setSelectedPaymentInstrumentId(
                newPaymentInstrument?.paymentInstrumentId
              );
              addNotification(
                formatMessage(paymentMessages.payPalPaymentMethodAdded)
              );
            })
            .catch(() => {
              setPayPalLoadingState(PAY_PAL_LOADING_OFF);
            });
        },
        onCancel: () => {
          setPayPalLoadingState(PAY_PAL_LOADING_OFF);
        },
        style: {
          layout: 'horizontal',
          color: 'gold',
          shape: 'pill',
          label: 'paypal',
          tagline: false,
          height: 55,
        },
      })
      .render('#paypal-button-container');
  };

  useEffect(() => {
    if (!payPalScriptLoaded) {
      loadScript(payPalScriptUrl);
    }
    if (payPalScriptLoaded) {
      renderPaypalButton();
    }
  }, [payPalScriptLoaded]);

  const handlePaymentChange = (ev) => {
    if (ev?.target?.value) {
      setSelectedPaymentInstrumentId(ev.target.value);
    }
  };

  const purchaseGiftHandler = () => {
    const addPaymentSelected = selectedPaymentInstrumentId === ADD_PAYMENT;
    const guestPaymentSelected = selectedPaymentInstrumentId === GUEST_PAYMENT;

    const paymentInstrumentToken =
      guestPaymentSelected && hasGuestPaymentInstrument
        ? guestPaymentInstrument.paymentInstrumentToken
        : null;

    if (addPaymentSelected) {
      setBottomSheetUrl();
      setSessionBottomSheetReturnUrl(bottomSheetUrl);

      return !signedIn
        ? push('/account/payment-method/add/billing-address')
        : push('/account/payment-method');
    }
    // purchase gift with standard payment methods

    const paymentInstrumentId = guestPaymentSelected
      ? null
      : selectedPaymentInstrumentId;
    const paymentType = getSelectedPaymentInstrumentType();

    return purchaseGift({
      guestPaymentSelected,
      openModal,
      closeModal,
      paymentInstrumentToken,
      paymentInstrumentId,
      paymentType,
      requestId,
      altText,
      largeImageUrl,
    });
  };

  const getFrapMessage = () => {
    const addPaymentSelected = selectedPaymentInstrumentId === ADD_PAYMENT;

    return addPaymentSelected ? (
      <FormattedMessage {...sharedCallsToAction.continue} />
    ) : (
      <FormattedMessage
        {...messages.sendGift}
        values={{ numberOfRecipients }}
      />
    );
  };

  if (hideGiftPaymentBottomSheet) {
    closeModal();
    return null;
  }

  return (
    <SharedBottomSheet
      headingChildren={formatMessage(messages.paymentMethodHeading)}
    >
      <p className="p3 my3 md-my4 borderRadius-sm bg-ceramic text-xxs text-semibold text-center">
        <FormattedMessage {...messages.doubleCheckRecipients} />
      </p>
      {!paymentInstrumentsLoading ? (
        <PaymentInstrumentSelect
          disabled={paymentInstrumentsLoading}
          onChange={handlePaymentChange}
          paymentInstruments={allGiftPaymentInstruments}
          value={selectedPaymentInstrumentId}
        />
      ) : (
        <LoadingCoffeeCup
          title={formatMessage(messages.loadingPaymentMethods)}
        />
      )}

      <div className="mt6">
        {giftAmount ? (
          <div className={`flex mt2 ${styles.total}`}>
            <span>
              <FormattedMessage {...commonMessages.total} />
            </span>
            <div className={styles.dotted} />
            <span>{formatNumber(giftAmount, formats.number.money)}</span>
          </div>
        ) : null}
      </div>
      <FrapContainer
        addSpacingForMultipleChildren={false}
        animated={false}
        relativeAbove="alwaysRelative"
      >
        <div
          className={!showPayPalButton ? 'hidden' : ''}
          data-e2e="paypalButtonContainer"
          id="paypal-button-container"
        />
        {!showPayPalButton ? (
          <Frap
            data-e2e="sendGift"
            loading={expectingGiftResponse || paymentServicesPending}
            onClick={purchaseGiftHandler}
          >
            {getFrapMessage()}
          </Frap>
        ) : null}
      </FrapContainer>
    </SharedBottomSheet>
  );
};

const select = (state) => ({
  allGiftPaymentInstruments: allGiftPaymentInstrumentsSelector(state),
  bottomSheetUrl: bottomSheetUrlSelector(state),
  currentCard: currentCardSelector(state),
  expectingGiftResponse: expectingGiftResponseSelector(state),
  giftAmount: giftAmountTotalSelector(state),
  giftFormData: giftFormDataSelector(state),
  guestPaymentInstrument: guestPaymentInstrumentSelector(state),
  hasGuestPaymentInstrument: hasGuestPaymentInstrumentSelector(state),
  hasRequiredGiftFormData: hasRequiredGiftFormDataSelector(state),
  isGroupGift: isGroupGiftSelector(state),
  paymentInstruments: giftPaymentInstrumentsSelector(state),
  paymentInstrumentsLoading: paymentInstrumentsLoadingSelector(state),
  paymentServicesPending: paymentServicesPendingSelector(state),
  payPalScriptLoaded: payPalScriptLoadedSelector(state),
  payPalScriptUrl: payPalScriptUrlSelector(state),
  profileData: profileDataSelector(state),
  profileStatus: profileStatusSelector(state),
  selectedPaymentInstrumentId: selectedGiftPaymentInstrumentIdSelector(state),
  signedIn: signedInSelector(state),
});

const actions = {
  addNotification: addNotificationAction,
  addPayPalPaymentInstrument: addPayPalPaymentInstrumentAction,
  getPayPalBillingAgreementToken: getPayPalBillingAgreementTokenAction,
  loadScript: loadScriptAction,
  purchaseGift: purchaseGiftAction,
  push: pushAction,
  setBottomSheetUrl: setBottomSheetUrlAction,
  setPayPalLoadingState: setPayPalLoadingStateAction,
  setSelectedPaymentInstrumentId: setSelectedPaymentInstrumentIdAction,
  updateGiftFormData: updateGiftFormDataAction,
};

export default connect(select, actions)(GiftPaymentBottomSheet);
