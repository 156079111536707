import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '@starbucks-web/pattern-library/lib/components/button/';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  configImagesUrlSelector,
  orderingEnabledSelector,
} from 'shared/app/state/selectors/config';
import { signedInSelector } from 'shared/app/bundles/user';
import { coreAppBaseUrlSelector } from 'shared/app/state/selectors/locales';
import JoinRewardsSigninSheet, {
  JoinSignInBottomSheetTitleId,
} from 'shared/app/components/join-rewards-signin-sheet';
import { sharedCallsToAction } from 'shared/app/messages/ctas';

import {
  unauthStreamEnabledSelector,
  streamDisplaySelector,
} from '../../../state/selectors/stream';

import CardBase from '../card-base';
import messages from './messages';

const Guest = ({
  imagesUrl,
  signedIn,
  coreAppBaseUrl,
  unauthStreamEnabled,
  streamItems,
  startOrderEnabled,
}) => {
  const { openModal } = useModalContext();
  const hasDynamicStreamContent = Boolean(
    unauthStreamEnabled && streamItems.length > 0
  );

  if (signedIn || hasDynamicStreamContent) return null;

  const addCardAction = () => {
    openModal({
      component: JoinRewardsSigninSheet,
      componentProps: {
        targetUrl: `${coreAppBaseUrl}/account/cards/add`,
        headingContent: (
          <FormattedMessage {...messages.toAddCardStarbucksMember} />
        ),
      },
      ariaLabelledBy: JoinSignInBottomSheetTitleId,
    });
  };

  const addCardButtonAttributes = {
    onClick: addCardAction,
    'data-e2e': 'registerCard',
  };
  return (
    <React.Fragment>
      {startOrderEnabled && (
        <CardBase
          body={<FormattedMessage {...messages.startOrderBody} />}
          imageAlt=""
          imagePosition="top"
          imageUrl={`${imagesUrl}/feed/guest-order.jpg`}
          title={<FormattedMessage {...messages.startOrderTitle} />}
        >
          <Button className="ml-auto" tagName={Link} to="/menu">
            <FormattedMessage {...sharedCallsToAction.startOrderCTA} />
          </Button>
        </CardBase>
      )}
      <CardBase
        body={<FormattedMessage {...messages.addCardBody} />}
        imageAlt=""
        imagePosition="top"
        imageUrl={`${imagesUrl}/feed/guest-add-card.jpg`}
        title={<FormattedMessage {...messages.addCardTitle} />}
      >
        <Button {...addCardButtonAttributes}>
          <FormattedMessage {...messages.addCardCTA} />
        </Button>
      </CardBase>
    </React.Fragment>
  );
};

const select = (state) => ({
  imagesUrl: configImagesUrlSelector(state),
  coreAppBaseUrl: coreAppBaseUrlSelector(state),
  signedIn: signedInSelector(state),
  unauthStreamEnabled: unauthStreamEnabledSelector(state),
  streamItems: streamDisplaySelector(state),
  startOrderEnabled: orderingEnabledSelector(state),
});

export default connect(select)(Guest);
