import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { push as pushAction } from 'redux-first-history';

import Button from '@starbucks-web/pattern-library/lib/components/button';

import {
  allPaymentInstrumentsSelector,
  paymentInstrumentsLoadingSelector,
  paymentServicesPendingSelector,
} from 'shared/app/bundles/wallet';
import {
  expectingReloadResponseSelector,
  svcCardsDataSelector as cardsDataSelector,
} from 'shared/app/bundles/svc-cards';

import Frap from 'shared/app/components/frap-with-messages';
import FrapContainer from 'shared/app/components/frap-container';
import MonetaryAmountSelect from 'shared/app/components/monetary-amount-select';
import PaymentInstrumentSelect from 'shared/app/components/payment-instrument-select';
import StoredValueCardsSelect from 'shared/app/components/stored-value-cards-select';

import { trackBarcodeCardReloadClick } from '../../state/actions/track-event';
import { svcReloadOptionsSelector } from '../../state/selectors';

import { sharedCallsToAction, svcMessages } from 'shared/app/messages';
import messages from './messages';

const AddMoneyBottomSheetForm = ({
  buttonMessage,
  card,
  fields,
  intl: { formatMessage },
  paymentInstruments,
  push,
  showPayPalButton,
  expectingReloadResponse,
  isPaymentInstrumentLoading,
  paymentServicesPending,
  svcCards,
  svcReloadOptions,
}) => {
  const autoReloadProfile = card?.autoReloadProfile ?? {};
  const autoReloadLabel = <FormattedMessage {...svcMessages.autoReloadLabel} />;

  return (
    <div className="pt4">
      <StoredValueCardsSelect field={fields.svcCard} svcCards={svcCards} />
      <div className="pl8">
        <MonetaryAmountSelect
          amounts={svcReloadOptions}
          {...fields.amount.input}
          label={formatMessage(messages.selectReloadAmount)}
        />
      </div>

      <PaymentInstrumentSelect
        disabled={isPaymentInstrumentLoading}
        paymentInstruments={paymentInstruments}
        {...fields.paymentInstrument.input}
      />

      <div className="mt4 flex justify-between items-end">
        <div className="flex items-center">
          <p>
            {autoReloadLabel}
            <span
              className="color-textBlackSoft inline-block ml2"
              data-e2e={
                autoReloadProfile.isActive
                  ? 'autoReloadEnabled'
                  : 'autoReloadDisabled'
              }
            >
              <FormattedMessage
                {...messages.autoReloadActive}
                values={{
                  isActive: Boolean(autoReloadProfile.isActive),
                }}
              />
            </span>
          </p>

          {autoReloadProfile.isActive ? (
            <p className="color-textBlackSoft mt1" data-e2e="autoReloadDetails">
              <FormattedMessage
                {...messages.autoReloadStatusMessage}
                values={{
                  amount: autoReloadProfile.amount,
                  triggerAmount: autoReloadProfile.triggerAmount,
                }}
              />
            </p>
          ) : null}
        </div>

        <Button
          data-e2e="editAutoReloadBottomSheet"
          onClick={() => {
            // Open auto reload bottom sheet.
            // The add money bottom sheet will close
            // because we are changing the url hash.
            push('#auto-reload');
          }}
          visualStyle="textOnly"
        >
          <FormattedMessage
            {...sharedCallsToAction.edit}
            values={{
              a11yMessage: (
                <span className="hiddenVisually">{autoReloadLabel}</span>
              ),
            }}
          />
        </Button>
      </div>

      <FrapContainer
        addSpacingForMultipleChildren={false}
        animated={false}
        relativeAbove="alwaysRelative"
      >
        <div
          className={!showPayPalButton ? 'hidden' : ''}
          id="paypal-button-container"
        />
        {!showPayPalButton && (
          <Frap
            data-e2e="addMoneyButtonBottomSheet"
            loading={expectingReloadResponse || paymentServicesPending}
            onClick={trackBarcodeCardReloadClick}
          >
            <FormattedMessage
              {...buttonMessage}
              values={{
                amount: fields.amount.input.value,
              }}
            />
          </Frap>
        )}
      </FrapContainer>
    </div>
  );
};

AddMoneyBottomSheetForm.propTypes = {
  buttonMessage: PropTypes.object,
  card: PropTypes.object,
  fields: PropTypes.object,
  intl: PropTypes.object,
  showPayPalButton: PropTypes.bool,
};

const select = (state) => ({
  expectingReloadResponse: expectingReloadResponseSelector(state),
  isPaymentInstrumentLoading: paymentInstrumentsLoadingSelector(state),
  paymentInstruments: allPaymentInstrumentsSelector(state),
  paymentServicesPending: paymentServicesPendingSelector(state),
  svcCards: cardsDataSelector(state),
  svcReloadOptions: svcReloadOptionsSelector(state),
});

const actions = { push: pushAction };

export default injectIntl(connect(select, actions)(AddMoneyBottomSheetForm));
