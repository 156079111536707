import PropTypes from 'prop-types';
import React, { forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Expander from '@starbucks-web/pattern-library/lib/components/expander';
import Icon from '@starbucks-web/pattern-library/lib/components/icon';
import cautionSolid from '@starbucks-web/pattern-library/lib/icons/caution-solid';
import info from '@starbucks-web/pattern-library/lib/icons/info';
import shouldDisplayError from '@starbucks-web/pattern-library/lib/components/form-container/should-display-error';
import { useModalContext } from '@starbucks-web/pattern-library/lib/components/modal-provider';

import {
  ADD_PAYMENT,
  allOrderPaymentInstrumentsSelector,
  paymentServicesPendingSelector,
  sfePaymentEnabledSelector,
} from 'shared/app/bundles/wallet';
import Frap from 'shared/app/components/frap-with-messages';
import FrapContainer from 'shared/app/components/frap-container';
import SharedForm from 'shared/app/components/shared-form';
import { hasSvcCardSelector } from 'shared/app/bundles/svc-cards';
import MonetaryAmountSelect from 'shared/app/components/monetary-amount-select';
import PaymentInstrumentSelect from 'shared/app/components/payment-instrument-select';
import { selectedStoreMopSvcOnly } from 'shared/app/state/selectors/ordering';

import PricingSummary from 'ordering/app/components/shared/pricing-summary';
import {
  isTippingEligibleSelector,
  selectedOrderPaymentSelector,
} from 'ordering/app/state/selectors';

import OrderPaymentSelect from './order-payment-select';
import TipAmountContainer from './tip-amount-container';
import { TipRemovedDialog, TIP_REMOVED_ID } from './tip-removed-dialog';

import messages from './messages';
import { paymentMessages } from 'shared/app/messages';

/* eslint-disable complexity */
/* eslint-disable max-statements */
export const OrderPaymentBottomSheetForm = forwardRef(
  (
    {
      buttonMessage,
      fields,
      inProgress,
      onSubmit,
      pricing,
      reloadAmounts,
      showReload,
      tipAmount,
      setTipAmount,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();
    const { openModal } = useModalContext();

    const isTippingEligible = useSelector(isTippingEligibleSelector);
    const paymentInstruments = useSelector(allOrderPaymentInstrumentsSelector);
    const paymentServicesPending = useSelector(paymentServicesPendingSelector);
    const hasSvcCard = useSelector(hasSvcCardSelector);
    const selectedOrderPayment = useSelector(selectedOrderPaymentSelector);
    const sfePaymentEnabled = useSelector(sfePaymentEnabledSelector);
    const svcPaymentsOnly = useSelector(selectedStoreMopSvcOnly);

    const pricingSummary = pricing?.summary;

    useEffect(() => {
      if (
        pricingSummary &&
        !isTippingEligible &&
        selectedOrderPayment.paymentType !== ADD_PAYMENT &&
        tipAmount > 0
      ) {
        openModal({
          component: TipRemovedDialog,
          ariaLabelledBy: TIP_REMOVED_ID,
        });
      }
    }, [isTippingEligible]);

    const svcSelectHintText = (includeHintText) => {
      if (!includeHintText) {
        return null;
      }
      return (
        <React.Fragment>
          <Icon className="color-yellow" path={cautionSolid} size="18px" />
          <span
            className="color-textBlackSoft pl2 text-xxs"
            data-e2e="insufficient-funds-warning"
          >
            {formatMessage(messages.insufficientFunds)}
          </span>
        </React.Fragment>
      );
    };

    return (
      <SharedForm onSubmit={onSubmit} ref={ref}>
        {/* when the temporary 3rd party message is removed, remove the:
            { || (!svcPaymentsOnly && sfePaymentEnabled) }
          part of expander logic */}
        <Expander
          isExpanded={
            svcPaymentsOnly || (!svcPaymentsOnly && sfePaymentEnabled)
          }
        >
          <div className={`flex justify-between pb3`}>
            <Icon className="self-center ml3" path={info} />
            <span
              className="ml3 pl3 pt1 color-textBlack text-xs"
              data-e2e="svc-payment-only-info"
            >
              {svcPaymentsOnly
                ? formatMessage(messages.svcPaymentOnly)
                : formatMessage(messages.thirdPartyPaymentAvailable)}
            </span>
          </div>
        </Expander>
        <OrderPaymentSelect
          field={fields?.payment}
          inputProps={{
            hintText: svcSelectHintText(showReload),
            errorMessage: formatMessage(messages.insufficientFunds),
            error: shouldDisplayError(fields.payment),
          }}
        />
        <Expander isExpanded={showReload}>
          <div className="flex mb2">
            <div className="flex-grow pr2">
              <PaymentInstrumentSelect
                label={formatMessage(paymentMessages.reloadSource)}
                paymentInstruments={paymentInstruments}
                {...fields?.reloadSource?.input}
              />
            </div>
            <div className="flex-grow pl2">
              <MonetaryAmountSelect
                amounts={reloadAmounts}
                label={formatMessage(messages.selectLoadAmount)}
                {...fields?.amount?.input}
              />
            </div>
          </div>
        </Expander>
        {isTippingEligible && (
          <TipAmountContainer
            setTipAmount={setTipAmount}
            tipAmount={tipAmount}
          />
        )}
        {pricingSummary && (
          <PricingSummary
            className="ml6 pl3 mt3"
            pricingSummary={pricingSummary}
            tipAmount={tipAmount}
          />
        )}
        {!hasSvcCard && (
          <span className="block pt3 color-red">
            {formatMessage(messages.noPaymentMethods)}
          </span>
        )}
        <FrapContainer
          addSpacingForMultipleChildren={false}
          animated={false}
          relativeAbove="alwaysRelative"
        >
          <Frap
            data-e2e="submitOrderButton"
            loading={inProgress || paymentServicesPending}
            type="submit"
          >
            <FormattedMessage {...buttonMessage} />
          </Frap>
        </FrapContainer>
      </SharedForm>
    );
  }
);

OrderPaymentBottomSheetForm.propTypes = {
  buttonMessage: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  paymentServicesPending: PropTypes.bool,
  pricing: PropTypes.object,
  reloadAmounts: PropTypes.array,
  showReload: PropTypes.bool,
};

export default OrderPaymentBottomSheetForm;
