import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Frap from 'shared/app/components/frap-with-messages';

import { signedInSelector } from 'shared/app/bundles/user';
import {
  primarySvcCardSelector as primaryCardSelector,
  hasSvcCardSelector,
} from 'shared/app/bundles/svc-cards';
import ContentCrateLoading from 'shared/app/components/content-crate-loading';
import FrapContainer from 'shared/app/components/frap-container';
import { isMobileViewportSelector } from 'shared/app/bundles/viewport';
import { sharedCallsToAction } from 'shared/app/messages/ctas';
import { svcMessages, sharedNotificationMessages } from 'shared/app/messages';
import OrderPickupCard from 'shared/app/components/order-pickup-card';

import {
  streamStatusSelector,
  unauthStreamEnabledSelector,
} from '../state/selectors/stream';
import { orderingEnabledSelector } from 'shared/app/state/selectors/config';
import { currentSessionMostRecentOrderSelector } from 'shared/app/state/selectors/ordering';
import {
  trackStreamFrapScanClick,
  trackStreamFrapStartCheckoutClick,
} from '../state/track-event';

import RewardsCard from './rewards-card';
import Guest from './stream-items/guest';
import StreamItems from './stream-items';
import OnboardingCard from './stream-items/onboarding';
import RewardsBenefitsCarousel from './rewards-benefits-carousel';
import messages from './messages';

import styles from './styles.cssm';

const MessageContainer = ({ children, className }) => (
  <div
    className={`container my4 text-center ${styles.message} ${className || ''}`}
  >
    {children}
  </div>
);

export const Stream = ({
  hasSvcCard,
  isMobileViewport,
  signedIn,
  startOrderEnabled,
  streamLoadingStatus,
  primaryCard,
  unauthStreamEnabled,
  mostRecentOrder,
}) => {
  const displayLoadingStatus = ({ showError, showLoading }) => {
    if (showLoading) {
      return <ContentCrateLoading />;
    }

    if (showError) {
      return (
        <React.Fragment>
          <MessageContainer>
            <p>
              <FormattedMessage
                {...sharedNotificationMessages.unexpectedError}
              />
            </p>
            <p>
              <FormattedMessage {...messages.refreshMessage} />
            </p>
          </MessageContainer>
        </React.Fragment>
      );
    }

    // Default to empty MessageContainer to ensure there's padding at the bottom of the Feed so
    // the Pay/Fav button doesn't overlap with the content of the Feed.
    // A valid scenario for this is when the stream has cards but there was an error refresh the Feed.
    return <MessageContainer />;
  };

  const renderSVCFrap = () => {
    const scanButton = (
      <Frap
        data-e2e="scanButton"
        mini={startOrderEnabled}
        onClick={trackStreamFrapScanClick}
        tagName={Link}
        to="#pay"
      >
        <FormattedMessage {...svcMessages.scanInStore} />
      </Frap>
    );

    const addCardButton = (
      <Frap mini={startOrderEnabled} tagName={Link} to="/account/cards/add">
        <FormattedMessage {...messages.addCardButton} />
      </Frap>
    );

    if (signedIn && isMobileViewport && hasSvcCard) {
      return primaryCard ? scanButton : addCardButton;
    }
  };

  const hideOrderPickupCard =
    !mostRecentOrder?.orderStatus ||
    (signedIn && mostRecentOrder?.orderStatus?.guest);

  return (
    <React.Fragment>
      {!signedIn && <RewardsBenefitsCarousel />}
      <div
        className={`md-pt7 md-px4 mx-auto lg-px0 ${styles.root}`}
        data-e2e="streamCardsContainer"
      >
        <OrderPickupCard isHidden={hideOrderPickupCard} />
        <RewardsCard />
        <OnboardingCard />
        <Guest />
        <StreamItems />
      </div>

      {(signedIn || unauthStreamEnabled) &&
        displayLoadingStatus(streamLoadingStatus)}
      <FrapContainer>
        {renderSVCFrap()}
        {startOrderEnabled && (
          <Frap
            data-e2e="startAnOrder"
            onClick={trackStreamFrapStartCheckoutClick}
            tagName={Link}
            to="/menu"
          >
            <FormattedMessage {...sharedCallsToAction.startAnOrder} />
          </Frap>
        )}
      </FrapContainer>
    </React.Fragment>
  );
};

Stream.propTypes = {
  primaryCard: PropTypes.object,
  signedIn: PropTypes.bool,
  hasSvcCard: PropTypes.bool,
  unauthStreamEnabled: PropTypes.bool,
  startOrderEnabled: PropTypes.bool,
  isMobileViewport: PropTypes.bool,
  streamLoadingStatus: PropTypes.shape({
    showError: PropTypes.bool,
    showLoading: PropTypes.bool,
  }),
};

const select = (state) => ({
  signedIn: signedInSelector(state),
  streamLoadingStatus: streamStatusSelector(state),
  primaryCard: primaryCardSelector(state),
  hasSvcCard: hasSvcCardSelector(state),
  unauthStreamEnabled: unauthStreamEnabledSelector(state),
  startOrderEnabled: orderingEnabledSelector(state),
  isMobileViewport: isMobileViewportSelector(state),
  mostRecentOrder: currentSessionMostRecentOrderSelector(state),
});

export default injectIntl(connect(select)(Stream));
