import { defineMessages } from 'react-intl';

export default defineMessages({
  distanceKmShort: {
    id: 'locationCard.kmShort',
    description: 'Shorthand display for kilometers',
    defaultMessage: '{distance} km',
  },
  distanceMilesShort: {
    id: 'locationCard.milesShort',
    description: 'Shorthand display for miles',
    defaultMessage: '{distance} mi',
  },
  signInRequired: {
    id: 'locatorErrorCard.mustLoginToFavorite.message',
    defaultMessage: 'You must sign in to add this store to your favorites.',
    description:
      'Message to the the user they must sign in to favorite a store',
  },
  deliveryToggleLegend: {
    id: 'storeLocator.deliveryToggle.deliveryToggleLegend',
    defaultMessage: 'Choose an order type',
    description: 'Hidden legend for the delivery radio toggle component',
  },
});
