import { createSelector } from 'reselect';
import { appTimeSelector } from 'shared/app/shell';

import { svcCardsLoadingSelector } from 'shared/app/bundles/svc-cards';
import {
  ADD_PAYMENT,
  defaultOrFirstPaymentInstrumentSelector,
  paymentInstrumentsLoadingSelector,
  sfePaymentEnabledSelector,
  validPaymentInstrumentsSelector,
  walletSVCsSelector,
} from 'shared/app/bundles/wallet';
import { selectedStoreMopSvcOnly } from 'shared/app/state/selectors/ordering';
import { pricingLoadingSelector, pricingSelector } from './cart';

export const selectedPaymentSelector = (state) =>
  state?.ordering?.selectedPayment;

export const selectedReloadSourceIdSelector = (state) =>
  state?.ordering?.selectedReloadSourceId;

export const orderPaymentDataLoadingSelector = createSelector(
  svcCardsLoadingSelector,
  paymentInstrumentsLoadingSelector,
  pricingLoadingSelector,
  (svcCardsLoading, paymentMethodLoading, pricingLoading) => {
    return Boolean(svcCardsLoading || paymentMethodLoading || pricingLoading);
  }
);

export const selectedPaymentBalanceSelector = createSelector(
  selectedPaymentSelector,
  (selectedPayment) => selectedPayment?.balance?.amount ?? 0
);

export const orderTotalSelector = createSelector(
  pricingSelector,
  (pricing) => pricing?.summary?.price ?? 0
);

export const pricingExpiredSelector = createSelector(
  pricingSelector,
  appTimeSelector,
  (pricing, appTime) => appTime > pricing?.expiresAt ?? null
);

/* eslint-disable max-params */
export const orderPaymentsSelector = createSelector(
  selectedStoreMopSvcOnly,
  sfePaymentEnabledSelector,
  validPaymentInstrumentsSelector,
  walletSVCsSelector,
  (svcOnlyStore, sfePaymentEnabled, paymentInstruments, SVCs) => {
    const addPaymentInstrument = {
      paymentType: ADD_PAYMENT,
      paymentInstrumentId: ADD_PAYMENT,
    };

    const paymentInstrumentsList =
      !svcOnlyStore && sfePaymentEnabled
        ? [...paymentInstruments, addPaymentInstrument]
        : [];

    return [...SVCs, ...paymentInstrumentsList]
      .sort((a, b) => {
        const aAction = a?.starpayActions?.find(
          (action) => action.type === 'MOP'
        );
        const bAction = b?.starpayActions?.find(
          (action) => action.type === 'MOP'
        );
        return aAction?.sortOrderIndex - bAction?.sortOrderIndex;
      })
      .filter(Boolean);
  }
);
/* eslint-enable max-params */

export const selectedOrderPaymentSelector = createSelector(
  orderPaymentsSelector,
  selectedPaymentSelector,
  (payments, selectedPayment) => selectedPayment || payments[0]
);

export const selectedOrderReloadSourceIdSelector = createSelector(
  defaultOrFirstPaymentInstrumentSelector,
  selectedReloadSourceIdSelector,
  (defaultPaymentInstrument, selectedReloadSourceId) =>
    selectedReloadSourceId ||
    defaultPaymentInstrument.paymentInstrumentId ||
    ADD_PAYMENT
);
